<template>
  <div class="loadingPanel" :class="{ 'is-visible': isVisible }">
    <div class="loaderPanel"></div>
  </div>
</template>

<script>
export default {
  name: 'LoadingPanel',
  data() {
    return {
      isVisible: false
    };
  },
  mounted() {
    setTimeout(() => {
      this.isVisible = true;
    }, 100);
  },
  props: {
    opacity: {
      type: Number,
      default: 0.5
    }
  }
}
</script>

<style scoped lang="scss">
@import "src/assets/scss/variables";

.loadingPanel {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  backdrop-filter: blur(2px);
  opacity: 0;
  visibility: hidden;
  transition: opacity 0.5s, visibility 0.5s;
}
.loadingPanel.is-visible {
  opacity: 1;
  visibility: visible;
}
.loaderPanel,
.loaderPanel:after {
  border-radius: 50%;
  width: 2rem;
  height: 2rem;
}

.loaderPanel {
  position: relative;
  text-indent: -9999em;
  border-top: 3px solid rgba($primary, .2);
  border-right: 3px solid rgba($primary, .2);
  border-bottom: 3px solid rgba($primary, .2);
  border-left: 3px solid $primary;
  transform: translateZ(0);
  animation: load8 1.1s infinite linear;
  //animation-delay: 1000ms !important;
}

@keyframes load8 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
</style>


<!-- <template>
  <div class="row" v-loading="true" id="loading"></div>
</template>
<script>
import Vue from 'vue';
import { Loading } from 'element-ui';

Vue.use(Loading.directive);
export default {};
</script>
<style>
#loading {
  min-height: 200px;
  display: flex;
  align-items: center;
}

.el-loading-spinner .path {
  stroke: #66615b !important;
}

.el-loading-mask {
  background: transparent !important;
}
</style> -->
