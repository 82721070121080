import Vue from "vue";
import Vuex from "vuex";
import i18n from "@/i18n";
import { useApi } from "@/modules/api";
import createPersistedState from "vuex-persistedstate";
import axios from 'axios';

Vue.use(Vuex);

const userStore = {
  state: {
    user: {},
  },
  mutations: {
    setUser(state, user) {
      state.user = user;
    },
    deleteUser(state) {
      state.user = 0;
    },
  },
  getters: {
    user: (state) => state.user,
    isAuthenticated: (state) => state.user?.email,
  },
};

const generalStore = {
  state: {
    accessStatus: null,
    currentData: null,
    darkMode: true,
    isLoading: false,
    dashLoading: false,
    errorFields: {},
    data: [],
    curentChartData: [],
    allChartData: {},
    message1: [],
    message2: [],
    allMessage2: [],
    lastMessages: [],
    ChatData_m6: [],
    ChatData_m2: [],
    ChatData_m4: [],
    ChatData_m5: [],

    ChatLable_m6: [],
    ChatLable_m2: [],
    ChatLable_m4: [],
    ChatLable_m5: [],

    batteryTime: [],
    timeZone: 0,
    timeZoneOffset: 0,
    showToast: false,
    lang: 0,
    updatable: false,
    avalableUpdates: 0,

    updateProgress: 0,

    na_schutz: [],
    batteryStatus: 0,
    timeNow: 0,
    lastOnlineFrom: 1,
    grid_voltage: 0,
    grid_power: 0,
    discharge_energy: 0,
    PV_power: 0,
    battery_power: 0,
    SOC: 0,
    charge_energy: 0,
    m8: 0,
    updateStarted: false,
    sns: [],
    sn: null,
    snUpdate: null,
    snUpdateList: [],
    snArray: [],

    isTotalSelected: false,
    chartSelector: {
      seriesSelected: "m0",
      timeSelected: "quarter",
      method: "mean",
      date: "",
    },
    updateChart: false,
    updateVisibility: 'hidden',
  },
  mutations: {
    setAccessStatus(state, status) {
      state.accessStatus = status;
    },
    resetSnUpdateList(state) {
      state.snUpdateList = [];
    },
    updateSnUpdateList(state, sn) {
      state.snUpdateList.push(sn);
    },
    setSnArray: (state, newArray) => {
      state.snArray = newArray;
    },
    setSnUpdateList: (state, newList) => {
      state.snUpdateList = newList;
    },
    setSnUpdate(state, newValue) {
      state.snUpdate = newValue;
    },
    setChatData_m6(state, data) {
      state.ChatData_m6 = data;
    },
    setChatLabel_m6(state, labels) {
      state.ChatLable_m6 = labels;
    },
    resetGeneralStore(state) {
      state.darkMode = true;
    },
    setDarkMode(state, darkMode) {
      state.darkMode = darkMode;
    },
    startLoading(state) {
      state.isLoading = true;
    },

    stopLoading(state) {
      state.isLoading = false;
    },
    startDashLoading(state) {
      state.dashLoading = true;
    },

    stopDashLoading(state) {
      state.dashLoading = false;
    },
    setErrorFields(state, errorFields) {
      state.errorFields = errorFields;
    },
    setShowToast(state, showToast) {
      state.showToast = showToast;
    },
    emptyErrorFields(state) {
      state.errorFields = {};
    },
    setCurentChartData(state, curentChartData) {
      state.curentChartData = curentChartData;
    },
    setAllChartData(state, allChartData) {
      state.allChartData = allChartData;
    },

    setChatData_m2(state, data) {
      state.ChatData_m2 = data;
    },
    setChatData_m4(state, data) {
      state.ChatData_m4 = data;
    },
    setChatData_m5(state, data) {
      state.ChatData_m5 = data;
    },
    setChatLabel_m2(state, labels) {
      state.ChatLable_m2 = labels;
    },
    setChatLabel_m4(state, labels) {
      state.ChatLable_m4 = labels;
    },
    setChatLabel_m5(state, labels) {
      state.ChatLable_m5 = labels;
    },

    set_xm2_data_label(state, data) {
      if (data[state.sn].length === 0) {
        state.ChatData_m2 = [];
        state.ChatLable_m2 = [];
        return;
      }
      const m2Values = [];
      const m2Labels = [];
      const entries = data[state.sn];
      const datesSet = new Set(
        entries.map((entry) => entry.dm_time.split("T")[0])
      );

      const currentDate = new Date(entries[0].dm_time.split("T")[0]); // First date in the dataset
      const lastDate = new Date(
        entries[entries.length - 1].dm_time.split("T")[0]
      ); // Last date in the dataset

      while (currentDate <= lastDate) {
        const formattedDate = currentDate.toISOString().split("T")[0];

        m2Labels.push(formattedDate);

        if (datesSet.has(formattedDate)) {
          const entry = entries.find(
            (e) => e.dm_time.split("T")[0] === formattedDate
          );
          m2Values.push(entry.m2);
        } else {
          m2Values.push(null);
        }

        currentDate.setDate(currentDate.getDate() + 1); // Move to the next day
      }

      state.ChatData_m2 = m2Values;
      state.ChatLable_m2 = m2Labels;
    },
    set_xm4_data_label(state, data) {
      if (data[state.sn].length === 0) {
        state.ChatData_m4 = [];
        state.ChatLable_m4 = [];
        return;
      }
      const m4Values = [];
      const m4Labels = [];
      const entries = data[state.sn];
      const datesSet = new Set(
        entries.map((entry) => entry.dm_time.split("T")[0])
      );

      const currentDate = new Date(entries[0].dm_time.split("T")[0]);
      const lastDate = new Date(
        entries[entries.length - 1].dm_time.split("T")[0]
      );

      while (currentDate <= lastDate) {
        const formattedDate = currentDate.toISOString().split("T")[0];

        m4Labels.push(formattedDate);

        if (datesSet.has(formattedDate)) {
          const entry = entries.find(
            (e) => e.dm_time.split("T")[0] === formattedDate
          );
          m4Values.push(entry.m4);
        } else {
          m4Values.push(null);
        }

        currentDate.setDate(currentDate.getDate() + 1); // Move to the next day
      }

      state.ChatData_m4 = m4Values;
      state.ChatLable_m4 = m4Labels;
    },
    set_xm5_data_label(state, data) {
      if (data[state.sn].length === 0) {
        state.ChatData_m5 = [];
        state.ChatLable_m5 = [];
        // console.log("state.ChatData_m5", state.ChatData_m5);
        return;
      }
      const m5Values = [];
      const m5Labels = [];
      const entries = data[state.sn];
      const datesSet = new Set(
        entries.map((entry) => entry.dm_time.split("T")[0])
      );

      const currentDate = new Date(entries[0].dm_time.split("T")[0]);
      const lastDate = new Date(
        entries[entries.length - 1].dm_time.split("T")[0]
      );

      while (currentDate <= lastDate) {
        const formattedDate = currentDate.toISOString().split("T")[0];

        m5Labels.push(formattedDate);

        if (datesSet.has(formattedDate)) {
          const entry = entries.find(
            (e) => e.dm_time.split("T")[0] === formattedDate
          );
          m5Values.push(entry.m5);
        } else {
          m5Values.push(null);
        }

        currentDate.setDate(currentDate.getDate() + 1); // Move to the next day
      }

      state.ChatData_m5 = m5Values;
      state.ChatLable_m5 = m5Labels;
    },
    set_xm6_data_label(state, data) {
      if (data[state.sn].length === 0) {
        state.ChatData_m6 = [];
        state.ChatLable_m6 = [];
        return;
      }

      const m6Values = [];
      const m6Labels = [];
      const entries = data[state.sn];
      const datesSet = new Set(
        entries.map((entry) => entry.dm_time.split("T")[0])
      );

      const currentDate = new Date(entries[0].dm_time.split("T")[0]); // First date in the dataset
      const lastDate = new Date(
        entries[entries.length - 1].dm_time.split("T")[0]
      ); // Last date in the dataset

      while (currentDate <= lastDate) {
        const formattedDate = currentDate.toISOString().split("T")[0];

        m6Labels.push(formattedDate);

        if (datesSet.has(formattedDate)) {
          const entry = entries.find(
            (e) => e.dm_time.split("T")[0] === formattedDate
          );
          m6Values.push(entry.m6);
        } else {
          m6Values.push(null);
        }

        currentDate.setDate(currentDate.getDate() + 1); // Move to the next day
      }
      state.ChatData_m6 = m6Values;
      state.ChatLable_m6 = m6Labels;
    },
    set_all_M_data(state, data) {
      try {
      if (data[state.sn].length === 0) {
        state.ChatData_m2 = [];
        state.ChatLable_m2 = [];
        state.ChatData_m4 = [];
        state.ChatLable_m4 = [];
        state.ChatData_m5 = [];
        state.ChatLable_m5 = [];
        return;
      }
    
      const entries = data[state.sn];

      const datesSet = new Set(
        entries.map((entry) => entry.dm_time.split("T")[0])
      );

      const currentDate = new Date(entries[0].dm_time.split("T")[0]); // First date in the dataset
      const lastDate = new Date(
        entries[entries.length - 1].dm_time.split("T")[0]
      ); // Last date in the dataset

      const m2Values = [];
      const m4Values = [];
      const m5Values = [];
      const m2Labels = [];
      const m4Labels = [];
      const m5Labels = [];

      while (currentDate <= lastDate) {
        const formattedDate = currentDate.toISOString().split("T")[0];

        m2Labels.push(formattedDate);
        m4Labels.push(formattedDate);
        m5Labels.push(formattedDate);

        if (datesSet.has(formattedDate)) {
          const entry = entries.find(
            (e) => e.dm_time.split("T")[0] === formattedDate
          );
          m2Values.push(entry.m2);
          m4Values.push(entry.m4);
          m5Values.push(entry.m5);
        } else {
          m2Values.push(null);
          m4Values.push(null);
          m5Values.push(null);
        }

        currentDate.setDate(currentDate.getDate() + 1); // Move to the next day
      }
      state.ChatData_m2 = m2Values;
      state.ChatData_m4 = m4Values;
      state.ChatData_m5 = m5Values;
      state.ChatLable_m2 = m2Labels;
      state.ChatLable_m4 = m4Labels;
      state.ChatLable_m5 = m5Labels;
    }catch (error) {
      console.log("error in set all :", error);
      // window.location.reload();
      console.log("reloaded");
    }
    },

    setData(state, data) {
      state.data = data;

      let message1 = {};
      let allMessage2 = {};
      let battery_status = {};
      let na_schutz = {};
      let last_messages = {};
      let grid_voltage = {};
      let grid_power = {};
      let discharge_energy = {};
      let PV_power = {};
      let battery_power = {};
      let SOC = {};
      let charge_energy = {};
      let m8 = {};
      let energy_forecast = {};
      let load_forecast = {};
      let battery_time = {};
      let time_zone = {};
      let time_zone_offset = {};

      let last_online_from = {};
      let updatable = {};
      let avalable_updates = {};

      let update_started = {};
      let update_progress = {};

      let lang = {};

      data.map((sn) => {
        let snString = Object.keys(sn)[0];

        message1[snString] = { message1: sn[snString].message1 };

        allMessage2[snString] = { message2: sn[snString].message2 };

        battery_status[snString] = {
          batteryStatus: sn[snString].battery_status,
        };

        na_schutz[snString] = { na_schutz: sn[snString].message2.na_schutz };

        last_messages[snString] = {
          last_messages: sn[snString].last_messages,
        };

        grid_voltage[snString] = { grid_voltage: sn[snString].grid_voltage };

        grid_power[snString] = { grid_power: sn[snString].grid_power };
        discharge_energy[snString] = {
          discharge_energy: sn[snString].discharge_energy,
        };
        PV_power[snString] = { PV_power: sn[snString].PV_power };
        battery_power[snString] = { battery_power: sn[snString].battery_power };
        SOC[snString] = { SOC: sn[snString].SOC };
        charge_energy[snString] = { charge_energy: sn[snString].charge_energy };
        m8[snString] = { m8: sn[snString].m8 };

        energy_forecast[snString] = {
          energy_forecast: sn[snString].energy_forecast,
        };
        load_forecast[snString] = { load_forecast: sn[snString].load_forecast };

        time_zone[snString] = { timeZone: sn[snString].time_zone };
        time_zone_offset[snString] = {
          timeZoneOffset: sn[snString].time_zone_offset,
        };
        battery_time[snString] = { batteryTime: sn[snString].time };

        last_online_from[snString] = {
          last_online_from: sn[snString].last_online_from,
        };

        updatable[snString] = { updatable: sn[snString].updatable };
        avalable_updates[snString] = {
          avalableUpdates: sn[snString].avalable_updates,
        };
        update_started[snString] = {
          updateStarted: sn[snString].update_started,
        };
        update_progress[snString] = {
          updateProgress: sn[snString].update_progress,
        };
        lang[snString] = { lang: sn[snString].lang };
        state.message1 = message1;
        state.allMessage2 = allMessage2;
        state.batteryStatus = battery_status;
        state.na_schutz = na_schutz;

        state.lastMessages = last_messages;
        state.grid_voltage = grid_voltage;
        state.grid_power = grid_power;
        state.discharge_energy = discharge_energy;
        state.PV_power = PV_power;
        state.battery_power = battery_power;
        state.SOC = SOC;
        state.charge_energy = charge_energy;
        state.m8 = m8;
        state.timeZone = time_zone;
        state.timeZoneOffset = time_zone_offset;

        state.timeNow = Vue.prototype
          .$dayjs()
          .utcOffset(state.timeZoneOffset).$d;
        state.lastOnlineFrom = last_online_from;

        state.updatable = updatable;
        state.avalableUpdates = avalable_updates;

        state.updateStarted = update_started;
        state.updateProgress = update_progress;

        state.lang = lang;
      });
    },
    setTime(state, batteryTime) {
      state.batteryTime = batteryTime;
    },
    setM1(state, grid_voltage) {
      state.grid_voltage = grid_voltage;
    },
    setM2(state, grid_power) {
      state.grid_power = grid_power;
    },

    setM3(state, discharge_energy) {
      state.discharge_energy = discharge_energy;
    },
    setM4(state, PV_power) {
      state.PV_power = PV_power;
    },
    setM5(state, battery_power) {
      state.battery_power = battery_power;
    },

    setM6(state, SOC) {
      state.SOC = SOC;
    },
    setM7(state, charge_energy) {
      state.charge_energy = charge_energy;
    },
    setM8(state, m8) {
      state.m8 = m8;
    },
    setUpdateProgress(state, updateProgress) {
      state.updateProgress = updateProgress;
    },
    setUpdateStarted(state, updateStarted) {
      state.updateStarted = updateStarted;
    },
    setMessage1(state, message1) {
      state.message1 = message1;
    },
    setMessage2(state, message2) {
      var gg = {};

      // console.log("state.message1: ", state.message1);
      Object.keys(message2).map((key) => {
        if (message2[key] == true && key !== "ein" && key !== "aus") {
          switch (key) {
            case "m":
              key = "M!";
              break;

            case "sm1":
              key = "SM1!";
              break;
            case "i2":
              key = "I>>";
              break;
            case "i1":
              key = "I>";
              break;
            case "i":
              key = "I!";
              break;
            case "relay":
              key = "Relay!";
              break;
            case "bat":
              key = "Bat!";
              break;
            case "u":
              key = "U!";
              break;
            case "hw":
              key = "HW!";
              break;
            case "sv":
              key = "SV!";
              break;

            default:
              break;
          }

          //console.log("sm2: ", state.message1);

          gg[key] = key;
        }
      });

      let message1 = state.message1[state.sn].message1;
      if (message1["sm2"] == true) {
        gg["SM2!"] = "SM2!";
      }

      if (message1["wait60"] == true) {
        gg[i18n.tc("warten60")] = "WARTEN 60s";
      }

      if (message1["sm_test"] == true) {
        gg["SM Test!"] = "SM Test!";
      }
      if (message2["na_schutz"]) {
        gg[message2["na_schutz"]] = message2["na_schutz"];
      }

      state.message2 = Object.keys(gg);
    },
    setallMessage2(state, allMessage2) {
      state.allMessage2 = allMessage2;
    },
    setNa_Schutz(state, na_schutz) {
      state.na_schutz = na_schutz;
    },
    setLastMessages(state, lastMessages) {
      let arr = [];
      lastMessages.map((lm) => {
        if (lm.time !== "2000-0-0 0:0:0") arr.push(lm);
      });
      state.lastMessages = arr;
    },
    setBatteryStatus(state, batteryStatus) {
      state.batteryStatus = batteryStatus;
      /*
      if (typeof ein_aus === 'boolean') {
        state.batteryStatus = ein_aus
      } else {
        state.batteryStatus = saxMaps.getOnOff(ein_aus)
      }*/
    },
    setTimeNow(state) {
      state.timeNow = Vue.prototype.$dayjs().utcOffset(state.timeZoneOffset).$d;
    },
    setTimeZoneOffset(state, offset) {
      // Vue.prototype.$dayjs().utcOffset(offset);
      // Vue.prototype.$dayjs.utcOffset = offset;
      state.timeZoneOffset = offset;
    },
    setTimeZone(state, timeZone) {
      // Vue.prototype.$dayjs().utcOffset(offset);
      // Vue.prototype.$dayjs.utcOffset = offset;
      state.timeZone = timeZone;
    },
    setLang(state, lang) {
      state.lang = lang;
    },
    setUpdatable(state, updatable) {
      state.updatable = updatable;
    },
    setAvalableUpdates(state, avalableUpdates) {
      state.avalableUpdates = avalableUpdates;
    },
    setLastOnlineFrom(state, lastOnlineFrom) {
      state.lastOnlineFrom = lastOnlineFrom;
    },
    async setSns(state, sns) {
      let newSns = [];

      sns.forEach((sn, i) => {
        newSns.push({ isActive: false, sn: sn.sn, phase: sn.phase });
      });
      if (sns.length > 1) {
        newSns.push({ isActive: true, sn: -1, phase: -1 });
      }
      else {
      newSns[0]["isActive"] = true;
      }
      state.sns = newSns;

      const snArray = [];

      for (const item of state.sns) {
        if (item.sn != -1) snArray.push(item.sn);
      }

      state.snArray = snArray;
      const activeSn = sns.length > 1 ? -1 : newSns[0].sn;
      await store.commit("setSn", activeSn);
    },
    async setSn(state, sn) {
      let snsArr = state.sns.map((sn) => {
        return sn.sn;
      });
      state.sn = sn;

      await state.sn;
    },
    updateSns(state, sns) {
      state.sns = sns;
    },
    setIsTotalSelected(state, value) {
      state.isTotalSelected = value;
    },
    setChartSelector(state, options) {
      state.chartSelector = options;
    },
    setUpdateChart(state, value) {
      state.updateChart = value;
    },
    SET_UPDATE_VISIBILITY(state, visibility) {
      state.updateVisibility = visibility;
    },
  },
  getters: {
    getAccessStatus: state => {
      return state.accessStatus;
    },
    getSnArray: (state) => {
      return state.snArray;
    },
    getSnUpdateList: (state) => {
      return state.snUpdateList;
    },

    getSnUpdate(state) {
      return state.snUpdate; s
    },
    getChatData_m6: (state) => state.ChatData_m6,
    getChatLabel_m6: (state) => state.ChatLable_m6,
    getDarkMode: (state) => state.darkMode,
    getChatData_m2: (state) => state.ChatData_m2,
    getChatData_m4: (state) => state.ChatData_m4,
    getChatData_m5: (state) => state.ChatData_m5,
    getChatLabel_m2: (state) => state.ChatLable_m2,
    getChatLabel_m4: (state) => state.ChatLable_m4,
    getChatLabel_m5: (state) => state.ChatLable_m5,
    isLoading: (state) => state.isLoading,
    dashLoading: (state) => state.dashLoading,
    errorFields: (state) => state.errorFields,
    curentChartData: (state) => state.curentChartData,
    allChartData: (state) => state.allChartData,
    data: (state) => state.data,
    message1: (state) => state.message1,
    message2: (state) => state.message2,
    allMessage2: (state) => state.allMessage2,
    na_schutz: (state) => state.na_schutz,
    lastMessages: (state) => state.lastMessages,
    batteryStatus: (state) => state.batteryStatus,
    timeNow: (state) => state.timeNow,
    timeZoneOffset: (state) => state.timeZoneOffset,
    timeZone: (state) => state.timeZone,
    showToast: (state) => state.showToast,
    lang: (state) => state.lang,
    updatable: (state) => state.updatable,

    lastOnlineFrom: (state) => state.lastOnlineFrom,
    avalableUpdates: (state) => state.avalableUpdates,
    grid_voltage: (state) => state.grid_voltage,
    grid_power: (state) => state.grid_power,
    discharge_energy: (state) => state.discharge_energy,
    PV_power: (state) => state.PV_power,
    battery_power: (state) => state.battery_power,
    SOC: (state) => state.SOC,
    charge_energy: (state) => state.charge_energy,
    m8: (state) => state.m8,
    updateProgress: (state) => state.updateProgress,
    updateStarted: (state) => state.updateStarted,
    sns: (state) => {
      try {
        state.sns.sort(function (a, b) {
          let keyA = a.phase,
            keyB = b.phase;
          // Compare the 2 dates
          if (keyA == -1 || keyB == -1) return 0;
          if (keyA < keyB) return -1;
          if (keyA > keyB) return 1;
          return 0;
        });
        // console.log("state.sns", state.sns);
      } catch (error) {
        console.error("error sort::", error);
      }

      return state.sns;
    },
    sn: (state) => state.sn,
    batteryTime: (state) => state.batteryTime,
    isTotalSelected: (state) => state.isTotalSelected,
    chartSelector: (state) => state.chartSelector,
    updateChart: (state) => state.updateChart,
    updateVisibility: state => state.updateVisibility,
  },

  actions: {
    resetDarkMode({ commit }) {
      commit("resetGeneralStore");
    },
    async getNewAccessToken() {
      const apiEndpoint = process.env.VUE_APP_API_ENDPOINT_BACK;
      const refreshToken = localStorage.getItem('refresh_token');

      try {
        const response = await axios.post(`${apiEndpoint}/api/auth/token/refresh/`, {
          refresh: refreshToken
        });

        const { access } = response.data;



        // You may also want to update the access token in local storage
        localStorage.setItem('access_token', access);
      } catch (error) {
        console.error('Error by getting new access', error);
      }
    },

    async sendUpdate({ state }) {
      const apiEndpoint = process.env.VUE_APP_API_ENDPOINT_TEST;

      try {
        const formData = new FormData();
        formData.append('sn', state.snUpdate);
        formData.append('update', '1');

        await axios.post(`${apiEndpoint}/api/v1/update/`, formData);

      } catch (error) {
        console.error(`Error sending update for sn :`, error);
        // console.error(`Error sending update for sn ${sn}:`, error);
      }
    },

    async checkStillUpdating({ dispatch, commit, state }) {
      const apiEndpoint = process.env.VUE_APP_API_ENDPOINT_TEST;
      const targetSN = Number(state.snUpdate);
      try {
        const response = await axios.get(`${apiEndpoint}/api/v1/has_update?sn=${targetSN}`);
        // console.log('targetSN',targetSN,'response:', response, 'snUpdateList', state.snUpdateList);
        if (response.data.new_update) {
          commit('SET_UPDATE_VISIBILITY', 'visible');
        }
        else {
          commit('SET_UPDATE_VISIBILITY', 'hidden');
          await dispatch('checkSnUpdate');
        }
      }
      catch (error) {
        console.error('Error in checkStillUpdating:', error);
      }

    },

    async checkSnUpdate({ state, commit }) {
      const apiEndpoint = process.env.VUE_APP_API_ENDPOINT_TEST;

      // console.log(" endPpoint : ", apiEndpoint);

      commit('resetSnUpdateList');

      for (const sn of state.snArray) {
        try {
          const response = await axios.get(`${apiEndpoint}/api/v1/has_update?sn=${sn}`);

          if (response.data.new_update) {
            // Update the nUpdateList
            commit('updateSnUpdateList', sn);
          }
        } catch (error) {
          console.error(`Error checking update for sn ${sn}:`, error);
        }
      }
    },

    async sendSnUpdate({ state, commit }) {

    },



    snUpdateList: ({ commit }, newList) => {
      commit("setSnUpdateList", newList);
    },
    updateSnArray: ({ commit }, newArray) => {
      commit("setSnArray", newArray);
    },
    updateSn({ commit }, newValue) {
      commit("setSnUpdate", newValue);
    },
    get_updateable_Sn: async (context) => {
      try {
        let response = await useApi.get("/api/v1/has_update?sn=1");

        context.commit("setData", response.data);
      } catch (error) {
        console.log("error get_current_data:", error);
        context.commit("stopLoading");
      }

      context.commit("stopLoading");
    },

    setChatData_m6({ commit }, data) {
      commit("setChatData_m6", data);
    },
    setChatLabel_m6({ commit }, labels) {
      commit("setChatLabel_m6", labels);
    },

    get_current_data: async (context) => {
      context.commit("startLoading");
      try {
        let response = await useApi.get("/api/auth/data/");

        // console.log("response", response.status);
        context.commit("setAccessStatus", response.status);

        context.commit("setData", response.data);
      } catch (error) {
        console.log("error get_current_data:", error);
        context.commit("stopLoading");
      }

      context.commit("stopLoading");
    },
    get_selected_data: async (context, options) => {
      context.commit("startLoading");

      try {
        let response = await useApi.get("/api/auth/graph/", {
          params: {
            interval:
              options["timeSelected"] === "date"
                ? "Day"
                : options["timeSelected"].charAt(0).toUpperCase() +
                options["timeSelected"].slice(1),
            from_date: options["date"],
            method: options["method"],
            sn: store.getters.sn + "",
          },
        });
        context.commit("setAllChartData", response["data"]);
      } catch (error) {
        console.log("error:", error);
        context.commit("stopLoading");
        context.commit("stopDashLoading");
      }

      context.commit("stopLoading");
    },
    get_apex_chart_data: async (context, options) => {
      try {
        let response = await useApi.get("/api/auth/apex_chart/", {
          params: {
            sn: options.sn, // assuming options.sn is the sn parameter
            m2: options.m2, // assuming options.m2 is the m2 parameter
            m4: options.m4, // assuming options.m4 is the m4 parameter
            m5: options.m5, // assuming options.m5 is the m5 parameter
            days: options.days, // assuming options.days is the days parameter
          },
        });
        if (options.m2 && options.m4 && options.m5) {
          context.commit("set_all_M_data", response.data);
        } else if (options.m2) {
          context.commit("set_xm2_data_label", response.data);
        } else if (options.m4) {
          context.commit("set_xm4_data_label", response.data);
        } else if (options.m5) {
          context.commit("set_xm5_data_label", response.data);
        }
      } catch (error) {
        console.log("error:", error);
      }
    },
    get_soc_chart_data: async (context, options) => {
      try {
        let response = await useApi.get("/api/auth/get_soc_data", {
          params: {
            sn: options.sn, // assuming options.sn is the sn parameter

            days: options.days, // assuming options.days is the days parameter
          },
        });
        context.commit("set_xm6_data_label", response.data);
      } catch (error) {
        console.log("error:", error);
      }
    },
    get_apex_chart_data_overview: async (context, options) => {
      try {
        const sns = store.getters.sns;
        // console.log("sns", sns);
        const snArray = sns.reduce((result, item) => {
          if (item.sn !== -1) {
            result.push(item.sn);
          }
          return result;
        }, []);
        const responseData = {};
        for (const sn of snArray) {
          let response = await useApi.get("/api/auth/apex_chart/", {
            params: {
              sn: sn,
              m2: options.m2,
              m4: options.m4,
              m5: options.m5,
              days: options.days,
            },
          });

          responseData[sn] = response.data[sn];
        }
        function removeTimeFromDates(data) {
          for (const key in data) {
            if (Object.hasOwnProperty.call(data, key)) {
              data[key].forEach((item) => {
                if (item.dm_time.includes("T")) {
                  item.dm_time = item.dm_time.split("T")[0];
                }
              });
            }
          }
          return data;
        }
        const updatedResponseData = removeTimeFromDates(responseData);
        let allDates = [];
        Object.values(updatedResponseData).forEach((item) => {
          item.forEach((entry) => {
            allDates.push(new Date(entry.dm_time));
          });
        });
        let minDate = new Date(Math.min(...allDates));
        let maxDate = new Date(Math.max(...allDates));
        function fillMissingDates(dataArray, minDate, maxDate) {
          const dateMap = {};
          dataArray.forEach((item) => {
            dateMap[item.dm_time] = true;
          });

          for (
            let d = new Date(minDate);
            d <= maxDate;
            d.setDate(d.getDate() + 1)
          ) {
            const currentDate = d.toISOString().split("T")[0];
            if (!dateMap[currentDate]) {
              dataArray.push({
                dm_time: currentDate,
                m2: null,
                m4: null,
                m5: null,
              });
            }
          }

          dataArray.sort((a, b) => new Date(a.dm_time) - new Date(b.dm_time));
          return dataArray;
        }
        Object.keys(updatedResponseData).forEach((key) => {
          fillMissingDates(updatedResponseData[key], minDate, maxDate);
        });
        // console.log("updatedResponseData", updatedResponseData);

        let overviewLabels = [];
        let firstKey = Object.keys(updatedResponseData)[0];
        updatedResponseData[firstKey].forEach((obj) => {
          overviewLabels.push(obj.dm_time);
        });

        let sumM2 = new Array(updatedResponseData[firstKey].length).fill(0);
        let sumM4 = new Array(updatedResponseData[firstKey].length).fill(0);
        let sumM5 = new Array(updatedResponseData[firstKey].length).fill(0);

        for (let key in updatedResponseData) {
          updatedResponseData[key].forEach((obj, index) => {
            if (obj.m2 !== null) {
              sumM2[index] += obj.m2;
            }
            if (obj.m4 !== null) {
              sumM4[index] += obj.m4;
            }
            if (obj.m5 !== null) {
              sumM5[index] += obj.m5;
            }
          });
        }
        sumM2 = sumM2.map((item) => item / snArray.length);
        sumM4 = sumM4.map((item) => item);
        sumM5 = sumM5.map((item) => item / snArray.length);

        if (options.m2 && options.m4 && options.m5) {
          context.commit("setChatData_m2", sumM2);
          context.commit("setChatData_m4", sumM4);
          context.commit("setChatData_m5", sumM5);
          context.commit("setChatLabel_m2", overviewLabels);
          context.commit("setChatLabel_m4", overviewLabels);
          context.commit("setChatLabel_m5", overviewLabels);
        } else if (options.m2) {
          context.commit("setChatData_m2", sumM2);
          context.commit("setChatLabel_m2", overviewLabels);
        } else if (options.m4) {
          context.commit("setChatData_m4", sumM4);
          context.commit("setChatLabel_m4", overviewLabels);
        } else if (options.m5) {
          context.commit("setChatData_m5", sumM5);
          context.commit("setChatLabel_m5", overviewLabels);
        }
      } catch (error) {
        console.error("Error occurred:", error);
      }
    },
    get_Soc_chart_data_overview: async (context, options) => {
      try {
        const sns = store.getters.sns;
        // console.log("sns", sns);
        const snArray = sns.reduce((result, item) => {
          if (item.sn !== -1) {
            result.push(item.sn);
          }
          return result;
        }, []);
        const responseData = {};
        for (const sn of snArray) {
          let response = await useApi.get("/api/auth/get_soc_data/", {
            params: {
              sn: sn,

              days: options.days,
            },
          });

          responseData[sn] = response.data[sn];
        }
        function removeTimeFromDates(data) {
          for (const key in data) {
            if (Object.hasOwnProperty.call(data, key)) {
              data[key].forEach((item) => {
                if (item.dm_time.includes("T")) {
                  item.dm_time = item.dm_time.split("T")[0];
                }
              });
            }
          }
          return data;
        }
        const updatedResponseData = removeTimeFromDates(responseData);
        let allDates = [];
        Object.values(updatedResponseData).forEach((item) => {
          item.forEach((entry) => {
            allDates.push(new Date(entry.dm_time));
          });
        });
        let minDate = new Date(Math.min(...allDates));
        let maxDate = new Date(Math.max(...allDates));
        function fillMissingDates(dataArray, minDate, maxDate) {
          const dateMap = {};
          dataArray.forEach((item) => {
            dateMap[item.dm_time] = true;
          });

          for (
            let d = new Date(minDate);
            d <= maxDate;
            d.setDate(d.getDate() + 1)
          ) {
            const currentDate = d.toISOString().split("T")[0];
            if (!dateMap[currentDate]) {
              dataArray.push({
                dm_time: currentDate,
                m6: null,

              });
            }
          }

          dataArray.sort((a, b) => new Date(a.dm_time) - new Date(b.dm_time));
          return dataArray;
        }
        Object.keys(updatedResponseData).forEach((key) => {
          fillMissingDates(updatedResponseData[key], minDate, maxDate);
        });
        // console.log("updatedResponseDataSOC", updatedResponseData);

        let overviewLabels = [];
        let firstKey = Object.keys(updatedResponseData)[0];
        updatedResponseData[firstKey].forEach((obj) => {
          overviewLabels.push(obj.dm_time);
        });


        let sumM6 = new Array(updatedResponseData[firstKey].length).fill(0);

        for (let key in updatedResponseData) {
          updatedResponseData[key].forEach((obj, index) => {
            if (obj.m6 !== null) {
              sumM6[index] += obj.m6;
            }
            else {
              sumM6[index] += 0;
            }

          });
        }
        // console.log("sumM6", sumM6);
        sumM6 = sumM6.map((item) => item / snArray.length);



        context.commit("setChatData_m6", sumM6);
        context.commit("setChatLabel_m6", overviewLabels);



      } catch (error) {
        console.error("Error occurred:", error);
      }
    },

    send_commands: (context, data) => {
      return new Promise((resolve, reject) => {
        context.commit("startLoading");
        useApi
          .post("/api/auth/command/", data)
          .then((response) => {
            resolve(response);
          })
          .catch((err) => {
            console.log(err);
            context.commit("stopLoading");
            reject(err);
          });
      });
    },

    get_sns: async (context) => {
      let response = await useApi.get("/api/auth/usr_sn/");
      // console.log("sns", response.data);
      context.commit("setSns", response.data);
    },
  },
};
export const store = new Vuex.Store({
  plugins: [
    createPersistedState({
      storage: window.sessionStorage,
    }),
  ],
  modules: {
    general: generalStore,
    user: userStore,
  },
});
