// import { store } from '../store/index'
// import { useApi } from './api'
import { TokenStorage } from "./token-storage";

export const useAuth = {
  setUser: (payload) => {
    // receive the JWT from the server and store it to localhost
    const access_token = payload ? payload.data.access : TokenStorage.getAccessToken();
    const refresh_token = payload ? payload.data.refresh : TokenStorage.getRefreshToken();

    // bail if no token
    if (!access_token) return Promise.resolve();

    // save tokens
    TokenStorage.storeAccessToken(access_token);
    TokenStorage.storeRefreshToken(refresh_token);
    TokenStorage.saveToken(access_token);
    TokenStorage.saveRefToken(refresh_token);

    // get additional user profile (like user-settings, username, other stuff)
    // return useApi.get('/api/auth/command/').then((response) => {
    // 	// store the user into vuex global store
    // 	store.commit('setUser', response.data.user)
    // }).catch(() => {
    // 	//console.log('Could not get user')
    // 	window.localStorage.removeItem(process.env.TOKEN_KEY)
    // 	store.commit('deleteUser')
    // })
  },
  logout: () => {
    TokenStorage.clear();
    // store.commit('deleteUser')
    return Promise.resolve();
  },
};
