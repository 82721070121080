import VueRouter from 'vue-router';
import routes from './routes';
import { TokenStorage } from "../modules/token-storage";
import { store } from "@/store";

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
  linkActiveClass: 'active',
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition
    } else {
      return { x: 0, y: 0 }
    }
  }
});


router.beforeEach((to, from, next) => {
  TokenStorage.isAuthenticated().then(isAuthenticated => {
    if (isAuthenticated) {
      if (to.name === "Login" || to.name === "Register") {
        next({ name: "Dashboard" });
      } else {
        next();
      }
    } else {
      if (to.name === "Dashboard") {
        next({ name: "Login" });
      } else {
        next();
      }
    }
  });
});
export default router;

