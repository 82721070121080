import axios from 'axios'

const ACCESS_TOKEN_STORE = process.env.VUE_APP_TOKEN_ACCESS_KEY
const REFRESH_TOKEN_STORE = process.env.VUE_APP_TOKEN_REFRESH_KEY

// const isAuthenticated() {
// 	return getAccessToken() !== null
// }

let dbPromise = new Promise((resolve, reject) => {
	const request = indexedDB.open("TokenDatabase", 1);

	request.onupgradeneeded = (event) => {
		const db = event.target.result;
		db.createObjectStore("tokens", { keyPath: "id" });
	};

	request.onsuccess = (event) => {
		const db = event.target.result;
		resolve(db);
	};

	request.onerror = (event) => {
		reject(event.target.error);
	};
});

export const TokenStorage = {

	async getAuthorizationHeader() {
		if (this.getAccessToken()) {
			return {
				headers: { 'Authorization': 'Bearer ' + this.getAccessToken() },
				'Content-Type': 'application/x-www-form-urlencoded'
			}
		} else {
			try {
				const token = await this.getTokenPromise.call(this);
				if (token !== null) {
					return {
						headers: { 'Authorization': 'Bearer ' + this.getAccessToken() },
						'Content-Type': 'application/x-www-form-urlencoded'
					}
				} else {
					return {}
				}
			} catch (error) {
				console.error('Error getting token:', error);
				return {}
			}

		}
	},
	async getNewToken() {
		if (this.getRefreshToken()) {
			return new Promise((resolve, reject) => {
				axios
					.post(process.env.VUE_APP_API_ENDPOINT + '/api/auth/token/refresh/', { refresh: this.getRefreshToken() })
					.then(response => {

						this.storeAccessToken(response.data.access)
						this.saveToken(response.data.access)

						resolve(response.data.access)
					})
					.catch((error) => {
						reject(error)
					})
			})
		}
		else {
			try {
				const RefToken = await this.getRefTokenPromise.call(this);
				if (RefToken) {
					console.log("refreshing from DB: ", RefToken);
					return new Promise((resolve, reject) => {
						axios
							.post(process.env.VUE_APP_API_ENDPOINT + '/api/auth/token/refresh/', { refresh: RefToken })
							.then(response => {

								this.storeAccessToken(response.data.access)
								this.saveToken(response.data.access)
								this.storeRefreshToken(RefToken)

								resolve(response.data.access)
							})
							.catch((error) => {
								reject(error)
							})
					})
				}
			} catch (error) {
				console.error('Error getting token:', error);
			}
		}
	},
	async isAuthenticated() {
		if (localStorage.getItem(ACCESS_TOKEN_STORE)) {
			return localStorage.getItem(ACCESS_TOKEN_STORE) !== null
		} else {
			try {
				const token = await this.getTokenPromise.call(this);
				console.log("isAuthenticated: ", token !== null);
				return token !== null;
			} catch (error) {
				console.error('Error getting token:', error);
				return false;
			}
		}
		// return localStorage.getItem(ACCESS_TOKEN_STORE) !== null
	},
	storeAccessToken(token) {
		localStorage.setItem(ACCESS_TOKEN_STORE, token)
	},
	storeRefreshToken(refreshToken) {
		localStorage.setItem(REFRESH_TOKEN_STORE, refreshToken)
	},
	getAccessToken() {
		return localStorage.getItem(ACCESS_TOKEN_STORE)
	},
	getRefreshToken() {
		return localStorage.getItem(REFRESH_TOKEN_STORE)
	},
	async clear() {
		const db = await dbPromise;
		const transaction = db.transaction(["tokens"], "readwrite");
		const store = transaction.objectStore("tokens");
		store.delete(ACCESS_TOKEN_STORE);
		store.delete(REFRESH_TOKEN_STORE);
		localStorage.removeItem(ACCESS_TOKEN_STORE)
		localStorage.removeItem(REFRESH_TOKEN_STORE)
	},
	async saveToken(token) {
		const db = await dbPromise;
		const transaction = db.transaction(["tokens"], "readwrite");
		const store = transaction.objectStore("tokens");
		store.put({ id: ACCESS_TOKEN_STORE, value: token });
	},
	async getToken(callback) {
		const db = await dbPromise;
		const transaction = db.transaction(["tokens"], "readonly");
		const store = transaction.objectStore("tokens");
		const request = store.get(ACCESS_TOKEN_STORE);

		request.onsuccess = (event) => {
			callback(event.target.result ? event.target.result.value : null);
		};
	},
	async saveRefToken(token) {
		const db = await dbPromise;
		const transaction = db.transaction(["tokens"], "readwrite");
		const store = transaction.objectStore("tokens");
		store.put({ id: REFRESH_TOKEN_STORE, value: token });
	},
	async getRefToken(callback) {
		const db = await dbPromise;
		const transaction = db.transaction(["tokens"], "readonly");
		const store = transaction.objectStore("tokens");
		const request = store.get(REFRESH_TOKEN_STORE);

		request.onsuccess = (event) => {
			callback(event.target.result ? event.target.result.value : null);
		};
	},
	getTokenPromise() {
		return new Promise((resolve, reject) => {
			this.getToken((token) => {
				if (token) {
					console.log("got Token: ", token);
					resolve(token);
				} else {
					// console.log("no Token");
					reject(new Error("No token found"));
				}
			});
		});
	},
	getRefTokenPromise() {
		return new Promise((resolve, reject) => {
			this.getRefToken((token) => {
				if (token) {
					console.log("got RefToken: ", token);
					resolve(token);
				} else {
					// console.log("no RefToken");
					reject(new Error("No RefToken found"));
				}
			});
		});
	}
}
